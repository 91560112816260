@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

:root {
  --primaryClr: #facb2f;
  --secondaryClr: #262626;
  --headClr: #111;
  --bodyClr: rgba(17, 24, 39, 1);
  --whiteClr: #ffffff;
  --blackClr: #1e1e1e;
  --lightClr: rgba(255, 255, 255, 0.6);
  --oddsBack: #72bbef;
  --oddsLay: #faa8ba;
  --siteRedClr: #d9534f;
  --siteGreenClr: #27ae60;
}
.light-mode:root {
  --primaryClr: #ffb80c;
  --secondaryClr: #999;
  --headClr: rgba(0, 0, 0, 0.175);
  --bodyClr: #ddd;
  --whiteClr: #000000;
  --blackClr: #ffffff;
}

body::-webkit-scrollbar {
  width: 5px;
  /* width of the entire scrollbar */
}

body::-webkit-scrollbar {
  background: orange;
  /* color of the tracking area */
}

body::-webkit-scrollbar {
  background-color: #0a0f19;
  border-radius: 20px;
  border: 3px solid #0a0f19;
}

html,
body {
  background: var(--bodyClr);
  color: var(--whiteClr);
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a,
.btn {
  text-decoration: none;
}

button:focus,
.btn:focus {
  outline: 0;
}

input:focus,
input.form-control:focus {
  outline: 0 !important;
  box-shadow: none;
}

.btn_primary {
  background: var(--primaryClr);
  color: var(--blackClr);
  font-size: 1rem;
  font-weight: 600;
  padding: 0.5rem 1rem;
  border: 1px solid var(--primaryClr);
  border-radius: 2rem;
  transition: all 0.2s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  &:hover {
    background: var(--primaryClr) !important;
    color: var(--blackClr) !important;
    border-color: var(--primaryClr) !important;
    opacity: 0.9;
  }
}

.btn_secondary {
  background: var(--whiteClr);
  color: var(--primaryClr);
  font-size: 1rem;
  font-weight: 600;
  padding: 0.5rem 1rem;
  border: 1px solidvar(--whiteClr);
  border-radius: 2rem;
  opacity: 0.9;
  transition: all 0.2s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  &:hover {
    background: var(--whiteClr) !important;
    color: var(--primaryClr) !important;
    border-color: var(--whiteClr) !important;
    opacity: 1;
  }
}

.btn_outline {
  background: transparent;
  color: var(--primaryClr);
  font-size: 1rem;
  font-weight: 600;
  padding: 0.5rem 1rem;
  border: 1px solidvar(--primaryClr);
  border-radius: 2rem;
  opacity: 0.9;
  transition: all 0.2s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  &:hover {
    background: var(--primaryClr) !important;
    color: var(--whiteClr) !important;
    border-color: var(--primaryClr) !important;
    opacity: 1;
  }
}

.login_btn {
  font-size: 1rem;
  font-weight: 600;
  color: var(--whiteClr);
  padding: 5px 15px;
  opacity: 0.9;
  transition: all 0.2s;
  border: 1px solid #eaa20f !important;
  border-radius: 30px;

  &:hover {
    border-radius: 30px !important;
    border: 1px solid #000 !important;
    background: #fcc200 !important;
    color: #000 !important;
    font-weight: 600;
  }
}

.signup_btn {
  border-radius: 30px;
  border: 1px solid #000;
  background: #fcc200;
  color: #000;
  font-weight: 600;

  &:hover {
    color: var(--whiteClr) !important;
    opacity: 1;
    border: 1px solid #eaa20f !important;
    background: #121212 !important;
    animation: pulse 1s;
    box-shadow: 0 0 0 2em transparent;
  }
}

.form-control {
  border-color: var(--primaryClr) !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px #0e0e12 inset !important;
  -webkit-text-fill-color: white !important;
}

.header {
  position: sticky;
  top: 0;
  left: 0;
  background: var(--blackClr);
  width: 100%;
  z-index: 999;
  box-shadow: 0 1px 3px 0 var(--primaryClr);
  .staticLogo {
    display: none;
    margin-left: 25px;
    width: 80px;
    @media only screen and (max-width: 767px) {
      margin-left: 10px;
      width: 65px !important;
    }
  }
  // background-color: rgb(255 255 255 / 90%);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  &.beforeheader {
    .top_head {
      // padding: 15px 0;
      // border-bottom: 1px solid #fd0203;
      @media (max-width: 991.98px) {
        padding: 5px 0;
      }
    }
  }
  &.aftrlgn {
    border-bottom: 1px solid var(--primaryClr);
    .top_head {
      padding: 5px 0;
    }
  }
  .middle-part {
    .navbar {
      .navbar-collapse {
        background-color: transparent !important;
      }
    }
  }
  .leftbar_toggle {
    .btn {
      background: transparent;
      border: 0;
      padding: 0;
      svg {
        width: 18px;
        height: 18px;
        display: block;
        fill: var(--primaryClr);
        visibility: inherit;
      }
      &:hover,
      &:focus {
        background: transparent !important;
      }
    }
  }
  .logo {
    max-width: 100px;
    @media screen and (max-width: 767.98px) {
      max-width: 80px;
    }
  }
  .navbar {
    padding: 0;
    .navbar-collapse {
      border-radius: 22px;
      .navbar-nav a {
        color: var(--whiteClr);
        padding: 0 5px;
        font-size: 14px;
        svg,
        img {
          fill: var(--primaryClr);
          width: 22px;
          height: 22px;
          margin-right: 5px;
        }
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
  .headerRight {
    display: flex;
    align-items: center;
    fill: var(--whiteClr);
    ul {
      display: flex;
      li {
        margin-right: 0;
        a {
          display: flex;
          align-items: center;
          color: #fff;
          img {
            width: 22px;
          }
          p {
            margin: 0;
            margin-left: 5px;
          }
        }
      }
    }

    align-items: center;
    .path-stroke {
      stroke: var(--whiteClr);
    }
    .dropdown {
      .btn {
        background: transparent;
        padding: 0;
        border: 0;
        color: #fff;
      }
      .dropdown-menu {
        min-width: 250px;
        .dropdown-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 5px 10px;
          h6 {
            margin-bottom: 0;
          }
          span {
            color: #ff5513;
            font-size: 12px;
            font-weight: 600;
          }
          &.deposit {
            background: var(--siteGreenClr);
            color: var(--whiteClr);
            font-weight: 600;
            width: calc(100% - 20px);
            text-align: center;
            display: block;
            margin: 10px;
            border-radius: 5px;
            &:hover {
              background: var(--siteGreenClr);
            }
          }
          &:hover {
            background: transparent;
          }
        }
      }
    }
    .funds {
      ul {
        li {
          a {
            color: var(--whiteClr);
            span {
              padding-left: 5px;
            }
            &.deposit {
              background: var(--siteGreenClr);
              font-weight: 600;
              padding: 7px 10px;
              border-radius: 3px;
            }
          }
        }
      }
    }
    .social {
      display: flex;
      align-items: center;
      margin-right: 5px;
      li {
        margin: 0 5px;
        position: relative;
        svg {
          fill: #000;
          border: 1px solid #000;
          border-radius: 50%;
        }
        .notification-count {
          width: 12px;
          height: 12px;
          background: var(--siteRedClr);
          color: var(--whiteClr);
          border-radius: 50%;
          position: absolute;
          right: -2px;
          top: -2px;
          font-size: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .menu-btn {
      padding: 0;
      background: none;
      border: none;
      &:hover,
      &:focus {
        background: none !important;
        border-color: transparent !important;
      }
      svg {
        width: 20px;
        height: auto;
        margin-left: 5px;
        fill: var(--primaryClr);
      }
    }
    .btn_signin {
      background: transparent;
      color: var(--whiteClr);
      border-color: var(--primaryClr);
      border-radius: 6px;
      text-transform: capitalize;
    }
    .btn_signup {
      background: var(--primaryClr);
      color: var(--blackClr);
      border-color: var(--primaryClr);
      border-radius: 5px;
      font-weight: 600;
    }
  }
  .bottom_head {
    background: var(--headClr);
    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      overflow-x: auto;
      text-align: center;
      height: 31px;
      overflow-x: auto;
      overflow-y: hidden;
      @media screen and (max-width: 991.98px) {
        justify-content: flex-start;
      }
      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
      li {
        display: inline-block;
        // margin: 0 5px;
        position: relative;
        &::before {
          // content: "";
          position: absolute;
          width: 1px;
          height: 12px;
          background-color: rgb(220 222 159 / 40%);
          right: -6px;
          top: 9px;
        }
        &:last-child::before {
          display: none !important;
        }
        a {
          color: var(--whiteClr);
          font-size: 12px;
          font-weight: 600;
          text-transform: uppercase;
          display: flex;
          align-items: center;
          width: max-content;
          padding: 6px 10px;
          position: relative;
          &::before {
            // content: "";
            position: absolute;
            background-color: var(--primaryClr);
            width: 12px;
            height: 2px;
            transition-duration: 0.3s;
            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            transform: translate(-50%, -50%);
            bottom: 0;
            left: 50%;
          }
          &:hover::before {
            width: 100%;
          }
          &:hover {
            color: var(--primaryClr);
          }
          img {
            width: 18px;
            height: 18px;
            margin-right: 10px;
          }
        }
      }
    }
  }
}

.back-link {
  background: transparent;
  color: var(--primaryClr);
  font-size: 0.85rem;
  font-weight: 600;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  height: max-content;
  margin-top: 0.65rem;
  padding: 0.5rem 1rem;
  border: 1px solidvar(--primaryClr);
  border-radius: 2rem;

  svg {
    margin-left: 0.5rem;
    transition: all 0.2s;
  }

  &:hover,
  &:focus {
    background: var(--primaryClr);
    color: var(--whiteClr);
    flex-direction: row-reverse;

    svg {
      margin-left: 0;
      margin-right: 0.5rem;
    }
  }
}

.offcanvas {
  border: none !important;
  z-index: 999999999 !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.22);
  @media only screen and (max-width: 767px) {
    width: 100% !important;
  }
}

.offcanvas-header {
  background: #0b101b;
  color: var(--whiteClr);

  .profile-img {
    width: 48px;
    height: 48px;
    background: #22252a;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    fill: white;

    svg {
      fill: gold;
    }
  }

  .user-info {
    color: var(--whiteClr);
    font-size: 14px;
    font-weight: 300;
    display: flex;
    flex-direction: column;
    justify-content: center;
    a {
      color: #fff !important;
    }
    span {
      font-size: 16px;
      font-weight: bold;
    }

    p {
      margin: 0;
    }
  }

  .btn-close {
    opacity: 1;
  }
}

.offcanvas-body {
  background: var(--bodyClr);
  .balance {
    font-size: 16px;
    color: var(--whiteClr);
    display: flex;
    align-items: center;
    svg {
      margin-right: 14px;
      fill: var(--primaryClr);
      stroke: var(--primaryClr);
    }
  }
  .balance-amount {
    text-align: right;
    font-weight: bold;
    font-size: 16px;
    color: var(--whiteClr);
  }
  .btn {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
  }
  .menu-items {
    border-top: 1px solid var(--whiteClr);
    margin: 30px -1rem 0 -1rem;
    li {
      border-bottom: 1px solid var(--whiteClr);
      a {
        display: flex;
        align-items: center;
        padding: 17px 1rem;
        font-size: 16px;
        color: var(--whiteClr);
        svg,
        img {
          margin-right: 20px;
          width: 22px;
          fill: var(--primaryClr);
          height: auto;
        }
      }
    }
  }
  ul.social_links {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    li {
      margin: 0 10px;
      position: relative;
      svg {
        fill: var(--whiteClr);
        stroke: var(--whiteClr);
        cursor: pointer;
      }
      img {
        width: 25px;
      }
      .notification-count {
        width: 12px;
        height: 12px;
        background: var(--siteRedClr);
        color: var(--whiteClr);
        border-radius: 50%;
        position: absolute;
        right: -2px;
        top: -2px;
        font-size: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.form-steps {
  text-align: center;
}

.main {
  padding-bottom: 30px;
  min-height: calc(100vh - 75px);
  .TopCategories {
    margin-bottom: 20px;
    border-top: 1px solid #d2d7ea;
    border-bottom: 1px solid #d2d7ea;

    ul {
      display: flex;
      margin: 0;
      list-style: none;
      overflow-x: auto;

      li {
        text-align: center;
        min-width: 80px;

        a {
          color: #fcc200;
          font-size: 1rem;
          font-weight: 400;
          text-decoration: none;
          display: flex;
          flex-direction: column;
          align-items: center;

          .IconHolder {
            border-radius: 5px;
            padding: 5px 8px 0 8px;
            width: 60px;
            height: auto;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          span {
            padding: 3px 0 2px;
            white-space: nowrap;
          }
        }

        &.active {
          background: var(--primaryClr);

          a {
            color: var(--whiteClr);
          }
        }
      }
    }
  }

  .mainBanner {
    .slick-slide {
      .content {
        position: absolute;
        left: 0;
        width: 60%;
        padding: 10px 0;
        margin: auto;

        h2 {
          color: var(--whiteClr);
          font-size: 3rem;
          max-width: 380px;

          span {
            color: var(--primaryClr);
          }
        }

        p {
          color: var(--whiteClr);
          font-size: 1.1rem;
          max-width: 350px;
        }
      }

      img {
        width: 100%;
        // min-height: 200px;
        object-fit: fill !important;
        aspect-ratio: 16/9 !important;
        max-height: 80vh;
      }
    }

    .slick-dots {
      bottom: 5px;

      li {
        margin: 0 0.25rem;
        width: 35px;
        height: 5px;

        &.slick-active button {
          background-color: rgb(252, 194, 0);
        }

        button {
          width: 30px;
          height: 3px;
          background: #fff;
          padding: 0;

          &::before {
            display: none;
          }
        }

        &.slick-active {
          button {
            &::before {
              color: #fcc200;
              opacity: 1;
              // background-color: rgb(252, 194, 0);
            }
          }
        }
      }
    }
  }

  .Live_casino_tabs {
    .nav-tabs {
      background: #1c2634;
      flex-wrap: nowrap;
      overflow-x: auto;
      border-bottom: 0;
      // border: 1px solid goldenrod;

      .nav-item {
        .nav-link {
          // background: #1C2634;
          color: #fff;
          font-size: 14px;
          background-repeat: no-repeat;
          background-repeat: no-repeat;
          background-position: 36px 10px;
          background-size: 50px 50px;
          padding: 65px 5px 5px 5px;
          width: 125px;
          height: 100%;
          border: 0;
          border-radius: 0px !important;
          // filter: grayscale(1);

          // &#uncontrolled-tab-example-tab-Roulette {
          //   background-image: url("../public/images/Roulette_Icons.png");
          // }

          // &#uncontrolled-tab-example-tab-Baccarat {
          //   background-image: url("../public/images/Baccarat_Icons.png");
          // }

          // &#uncontrolled-tab-example-tab-Andar\ Bahar {
          //   background-image: url("../public/images/Andar_bahar_Icons.png");
          // }

          // &#uncontrolled-tab-example-tab-Poker {
          //   background-image: url("../public/images/Poker_Icons.png");
          // }

          // &#uncontrolled-tab-example-tab-Blackjack {
          //   background-image: url("../public/images/Blackjack_icons.png");
          // }

          // &#uncontrolled-tab-example-tab-TeenPatti {
          //   background-image: url("../public/images/Teenpatti.png");
          // }

          // &#uncontrolled-tab-example-tab-32Card {
          //   background-image: url("../public/images/casino.png");
          // }

          // &#uncontrolled-tab-example-tab-Seven {
          //   background-image: url("../public/images/seven.png");
          // }

          // &#uncontrolled-tab-example-tab-Dices {
          //   background-image: url("../public/images/dices.png");
          // }

          // &#uncontrolled-tab-example-tab-BetNumber {
          //   background-image: url("../public/images/bet_on_numbers.png");
          // }

          &:hover,
          &.active {
            background-color: #0a0f19;
            color: #fcc200;
            border: 0;
            // filter: grayscale(1);
            border: 1px solid #daa52000;
          }
        }
      }
    }

    .top_Sec {
      display: grid;
      grid-gap: 1.5rem 0.75rem;
      grid-template-columns: repeat(4, 1fr);
      padding: 1rem 0.75rem;
    }
  }

  .sectionTitle {
    font-size: 20px;
    font-weight: bold;
    color: var(--whiteClr);
    position: relative;
    display: inline-block;
    margin-bottom: 20px;
    padding-top: 1rem;
    padding-bottom: 5px;

    &:after {
      content: " ";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 50%;
      height: 1px;
      background: var(--primaryClr);
    }
  }

  .innerBanner {
    margin-bottom: 30px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 10px 10px #0f121a;

    .link {
      width: 100%;
      background: var(--primaryClr);
      font-size: 11px;
      font-weight: 500;
      display: block;
      text-align: center;
      color: var(--whiteClr);
      text-transform: uppercase;
      padding: 8px;
    }
  }

  .payment-info {
    color: var(--whiteClr);

    .balance {
      font-size: 16px;
      display: flex;
      align-items: center;

      svg {
        margin-right: 14px;
        fill: var(--primaryClr);
        stroke: var(--primaryClr);
        opacity: 1;
      }
    }

    .balance-amount {
      text-align: right;
      font-weight: bold;
      font-size: 16px;
    }

    .btn {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 500;

      &.btn-outline-secondary {
        border-color: var(--whiteClr);
        color: var(--whiteClr);
      }

      border-radius: 0.25rem;
    }
  }

  .account-statement {
    h3 {
      color: var(--whiteClr);
      font-size: 16px;
      font-weight: 300;
      margin: 12px 0 22px;
      padding: 0 1rem 12px 1rem;
      border-bottom: 1px solidvar(--whiteClr);
    }

    .form-control {
      background: transparent;
      color: var(--whiteClr);
      border-radius: 0.25rem;
    }

    .btn-primary {
      background: var(--primaryClr);
      width: 34px !important;
      // padding: 6px 10px;
      border-color: var(--primaryClr);
      border-radius: 0 0.25rem 0.25rem 0;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      height: 100%;
      z-index: 99;
    }

    ul {
      margin: 0;
      border-top: 1px solidvar(--whiteClr);

      li {
        padding: 22px 1rem;
        border-bottom: 1px solidvar(--whiteClr);

        .h4 {
          font-size: 14px;
          margin-bottom: 0;
        }

        .h5 {
          margin-bottom: 0;
          font-size: 12px;
          letter-spacing: 0.86px;
        }

        .details {
          font-size: 12px;
          font-weight: 300;
        }

        .statue {
          font-size: 10px;
          font-weight: 300;
          letter-spacing: 0.7px;
        }

        small {
          margin-top: 10px;
          font-size: 10px;
          font-weight: 300;
          display: block;
        }
      }
    }
  }

  .profile-img {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    img {
      max-width: 160px;
      width: 160px;
      height: 160px;
      border-radius: 10%;
      border: 2px solid #fcc200;
    }

    span {
      margin-top: 8px;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .edit-btn {
    background: var(--primaryClr);
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    fill: var(--whiteClr);
    padding: 4px;
    top: 58px;
    right: -25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .profile-info {
    th {
      background: #0e1422;
      color: #fff;
    }
    td {
      background: #0e1422;
      color: #fff;
      border-bottom-width: 0px;
      @media (max-width: 767.98px) {
        min-width: 120px;
      }
    }
    .edit-btn {
      top: 0;
      right: 0;
      margin-left: auto;
    }
  }
}

.listings {
  .filter {
    margin-bottom: 20px;

    .form-control {
      background: var(--bodyClr);
      color: var(--whiteClr);
      border-color: var(--primaryClr);
    }

    .dropdown-toggle {
      background: transparent !important;
      border-radius: 5px;
      padding: 10px 15px;
      border: 1px solid #ffc400 !important;
      color: gold;
      box-shadow: 0px 4px 10px #ffd7003b;
      width: 100%;
      text-align: left;
      position: relative;
    }

    .dropdown-toggle::after {
      display: inline-block;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: "";
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent;
      position: absolute;
      right: 10px;
      top: 50%;
    }

    .dropdown-item.active,
    .dropdown-item:active {
      color: #e4b103;
      text-decoration: none;
      background-color: #0a0f19 !important;
      border: 1px solid #fcc200 !important;
      border-radius: 5px;
    }

    .btn-outline-primary {
      width: 60%;
      padding: 10px;
      margin-left: 2px;
      background: #0a0f19;
      // padding-right: 25px;
      // padding-left: 10px;
      border-color: #fcc200;
      border-radius: 5px !important;
      box-shadow: 0px 4px 10px rgb(255 215 0 / 23%);
    }

    .dropdown-menu {
      background: var(--whiteClr);
      border-radius: 0;
      width: 100% !important;
      white-space: pre-wrap;
      min-width: auto;
      padding: 0 !important;
      margin-top: -3px;

      a {
        border-bottom: 1px solid #222;
        color: var(--whiteClr);
        font-size: 14px;
        padding: 0.75rem 1rem;
        margin: 0;
        background-color: #0a0f19;

        &:last-child {
          border: none;
        }

        &:hover {
          background: var(--primaryClr);
          color: var(--whiteClr);
        }
      }
    }
  }

  .like-btn {
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.4);
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 4px 9px;
    border-radius: 0 0 7px 0;

    svg {
      fill: var(--whiteClr);
    }
  }
}

.steps-canvas {
  .offcanvas-header {
    // background: var(--primaryClr);

    .offcanvas-title {
      font-size: 20px;
      font-weight: bold;
      color: var(--whiteClr);
    }
  }

  .form-steps {
    padding: 20px;
    border-radius: 0.25rem;

    .h2 {
      font-size: 28px;
      font-weight: 500;
      color: var(--whiteClr);
    }

    p {
      font-size: 18px;
      font-weight: 300;
      color: var(--whiteClr);
    }

    input {
      background: transparent !important;
      border-radius: 0.25rem !important;
      border: 1px solidvar(--primaryClr) !important;
      padding: 14px 20px;
      color: var(--whiteClr) !important;
      margin-top: 15px !important;
    }

    .flag-dropdown {
      border: 1px solid var(--primaryClr);
      .selected-flag {
        background: var(--bodyClr);
      }
      .country-list {
        overflow-y: auto;
      }
    }

    .icon {
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translate(0, -50%);
      fill: #646464;
      svg {
        width: 18px;
        height: 18px;
      }
      img {
        filter: invert(0.5);
      }
    }

    .btn {
      // width: 100%;
      padding: 9px 30px;
    }

    .form-link {
      font-size: 14px;
      color: var(--whiteClr);
      text-decoration: underline;
    }

    .sign-up {
      font-size: 14px;
      font-weight: 500;
      a {
        font-size: 16px;
        color: var(--primaryClr);
        text-decoration: underline;
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
}

.tabs-area {
  margin-top: 10px;

  ul {
    flex-wrap: nowrap;
    border: 0;

    li {
      width: 100%;
      border: 5px solid #0a0f19;
      .nav-link {
        width: 100%;
        background: #1d1d1d;
        color: rgba(255, 255, 255, 0.6);
        border-radius: 10px;
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;
        padding: 17px 10px;
        border: none;

        &.active {
          background: var(--primaryClr);
          color: var(--whiteClr);
        }
      }
    }
  }

  .tab-content {
    .bank-details {
      margin-top: 22px;
      background: var(--whiteClr);
      box-shadow: 3px 3px 8px rgba(252, 194, 0, 0.12);
      border-radius: 0.5rem;
      overflow: hidden;

      .top-area {
        background: #1d1d1d;
        width: 100%;
        font-size: 14px;
        padding: 20px;
      }

      .bottom-area {
        background: var(--bodyClr);
        padding: 20px;

        table {
          margin-bottom: 0;

          th {
            color: var(--whiteClr);
            font-size: 18px;
            font-weight: 500;
            padding: 5px 0;
          }

          td {
            color: var(--whiteClr);
            font-size: 14px;
            font-weight: 300;
            padding: 5px 0;
            max-width: 170px;
          }
        }
      }
    }

    .upi-list {
      margin: 20px 0 40px;

      ul {
        display: flex;
        justify-content: center;
        align-items: center;

        li {
          margin: 0 7px;
          width: 32px;
          border: none;
        }
      }
    }

    form {
      padding: 50px 30px 30px;
      text-align: center;

      input {
        background: transparent;
        color: var(--whiteClr);
        padding: 14px 20px;

        &.form-check-input {
          border-radius: 50px;
          width: 24px;
          height: 24px;
          border: 2px solidvar(--primaryClr);
          padding: 0;
          background-color: var(--whiteClr);
          position: relative;

          &:checked:after {
            content: "";
            background: var(--whiteClr);
            border-radius: 50px;
            width: 16px;
            height: 16px;
            display: block;
            position: absolute;
            top: 2px;
            left: 2px;
          }
        }
      }

      .btn {
        padding: 9px 30px;
        font-size: 14px;
        text-transform: uppercase;
        // min-width: 120px;
      }

      .radio-btns {
        display: flex;

        .form-check {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: 300;

          .form-check-label {
            margin-left: 5px;
            margin-top: 3px;
          }
        }
      }
    }

    .card-holder {
      height: 115px;
      background: #1d1d1d;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 5px 5px 10px #0000001a;

      img {
        max-height: 40px;
      }
    }
  }

  .h4 {
    font-size: 14px;
    font-weight: 500;
  }

  .sectionTitle {
    &::after {
      background: var(--whiteClr);
      width: 100%;
    }
  }
}

.custom-tabs {
  .nav {
    flex-wrap: nowrap;
    background: #f2f2f2;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;

    box-shadow: 0 3px 12px rgb(64 95 186 / 22%);

    li {
      margin: 0 10px;

      .nav-link {
        border-radius: 0;
        font-size: 12px;
        padding: 10px 0;
        color: var(--whiteClr);
        font-weight: 500;
        border: none;

        &.active {
          color: var(--primaryClr);
          background: none;
        }
      }
    }
  }

  .tab-pane {
    .title {
      background: #d8d8d8;
      display: flex;
      justify-content: space-between;

      span {
        font-size: 14px;
        padding: 6px 1rem;
        display: inline-block;
        background: var(--primaryClr);
        color: var(--whiteClr);
      }
    }

    .game-listing {
      .breadcrumb {
        font-size: 12px;
        display: flex;
        align-items: center;

        span {
          font-size: 10px;
          color: #838383;
        }
      }

      .status {
        background: #4caf50;
        display: flex;
        padding: 1px 5px;
        font-size: 9px;
        border-radius: 2px;
        align-items: center;
        justify-content: center;
      }

      .listing {
        display: flex;
        flex-wrap: nowrap;
        margin: 0 -0.7rem;

        li {
          margin: 0 2px;
          width: 100%;

          .info-block {
            display: flex;
            flex-direction: column;
            align-items: center;
            background: #899d8a;
            font-size: 10px;
            border-radius: 2px;
            padding: 2px 4px;

            span {
              font-size: 12px;
            }
          }
        }
      }
    }

    .top-row {
      display: flex;
      justify-content: space-between;
      background: #d8d8d8;
      margin: 20px 0;
      padding: 0 1rem;

      .bet-btns {
        display: flex;

        li {
          background: #4caf50;
          color: var(--whiteClr);
          margin: 4px 0px 4px 4px;
          text-transform: uppercase;
          font-size: 11px;
          padding: 4px 8px;
          border-radius: 2px;
        }
      }
    }

    .status-btns {
      display: flex;
      align-items: center;

      .info-block {
        font-size: 10px;
        margin: 4px 0px 4px 4px;
        border-radius: 2px;
        padding: 3px 2px;
        min-width: 36px;
        text-align: center;
        color: var(--whiteClr);

        &.no {
          background: #796b4a;
        }

        &.yes {
          background: #2b3970;
        }
      }
    }

    .game-points {
      ul {
        margin: 0 -1rem;

        margin: 0;

        li {
          display: flex;
          border-bottom: 1px solid #eee;
          padding: 3px 1rem;
          align-items: center;
          font-size: 11px;

          .team-name {
            display: flex;
            flex-direction: column;
            line-height: normal;

            .time {
              color: #af2f2f;
            }
          }

          .score {
            margin-left: auto;
          }

          .status-btns {
            margin-left: 5px;
            position: relative;

            .info-block {
              display: flex;
              flex-direction: column;
              align-items: center;
              font-size: 8px;
              line-height: normal;

              span {
                font-size: 12px;
              }
            }

            .points-status {
              position: absolute;
              top: 4px;
              left: 4px;
              width: 95%;
              background: rgba(0, 0, 0, 0.7);
              font-size: 12px;
              color: var(--whiteClr);
              text-align: center;
              border-radius: 2px;
              height: 80%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }

    .game-top-area {
      background: #d8d8d8;
      padding: 10px 1rem;

      .date {
        text-align: center;
        color: #4caf50;
        font-size: 12px;
        margin-bottom: 5px;
      }

      .title {
        margin: 0;
        padding: 0;
        font-size: 14px;
      }

      .range {
        background: #4caf50;
        font-size: 11px;
        color: var(--whiteClr);
        padding: 2px 5px;
        line-height: normal;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.footer {
  padding: 0px 18px 70px 18px;
  .providers_sec {
    background: var(--blackClr);
    padding: 2rem;
    margin-bottom: 2.5rem;
    border: 1px solid rgba(255, 255, 255, 0.05);
    border-radius: 1.5rem;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      height: 80%;
      width: 1px;
      background-color: #7e7e7e;
      left: 50%;
      top: 10%;
      transform: translate(-50% - -50%);
    }
    @media screen and (max-width: 1199.98px) {
      padding: 1rem;
    }
    .heading {
      text-align: center;
      background: #1e1e1e;
      justify-content: center;
      h5 {
        font-size: 22px;
        font-weight: bold;
      }
      @media screen and (max-width: 1199.98px) {
        margin: 0 auto;
      }
      p {
        span {
          color: var(--primaryClr);
        }
      }
    }
    .providers_logo {
      // width: calc(100% - 300px);
      &.payment_logo {
        a {
          width: 120px !important;
        }
      }
      @media screen and (max-width: 1199.98px) {
        width: calc(100% - 0px);
        margin-bottom: 20px !important;
      }
      .logo_box {
        margin: 3px;
        text-align: center;
        img {
          max-width: 120px;
          padding: 3px;
          &.payment-height {
            max-width: 55px;
          }
        }
      }
    }
  }
  .providers_img {
    text-align: center;
    a {
      display: inline-block;
      padding: 0 18px;
      max-width: 130px;
      img {
        filter: invert(1);
      }
    }
  }
  .helpline {
    background: #222;
    max-width: 300px;
    text-align: center;
    padding: 10px;
    margin: 10px auto;
    border: 1px solid #333;
    border-radius: 10px;
    h3 {
      font-size: 21px;
      font-weight: 700;
    }
    a {
      color: var(--primaryClr);
      font-size: 15px;
      font-weight: 700;
    }
  }
  p {
    color: var(--whiteClr);
    font-size: 14px;
    line-height: 24px;
    font-weight: 300;
  }
  .betfair {
    max-width: 110px;
    height: auto;
    filter: invert(1);
  }
  .footer-logo {
    max-width: 150px;
    margin-bottom: 20px;
  }
  .paymentImages {
    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      li {
        padding: 8px;
        img {
          max-height: 46px;
        }
      }
    }
  }
  .footer-links {
    margin-top: 10px;
    ul {
      text-align: center;
      padding: 7px 0;
      border-bottom: 1px solid #333;
      li {
        font-size: 12px;
        font-weight: 300;
        display: inline-block;
        border-right: 1px solid #5c6060;
        &:last-child {
          margin-right: 0;
          border-right: 0;
          &::after {
            display: none;
          }
        }
        a {
          color: var(--whiteClr);
          font-size: 15px;
          padding: 0 15px;
          &:hover,
          &:focus {
            color: #5c6060;
          }
        }
      }
    }
  }
  .social-icons {
    padding-top: 25px;
    ul {
      display: flex;
      justify-content: center;
      li {
        margin-right: 15px;
        position: relative;
        &:last-child {
          margin-right: 0;
        }
        img {
          width: 40px;
          height: auto;
          cursor: pointer;
        }
        svg {
          width: 32px;
        }
        .notification-count {
          width: 12px;
          height: 12px;
          background: var(--siteRedClr);
          color: var(--whiteClr);
          border-radius: 50%;
          position: absolute;
          right: -2px;
          top: -2px;
          font-size: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  .footer-menu {
    background: rgba(17, 17, 17, 0.97);
    width: 100%;
    margin-bottom: 4px;
    border: 0px solid var(--primaryClr);
    border-left-width: 2px;
    border-right-width: 2px;
    border-radius: 40px;
    box-shadow: 0 0 5px #111, inset 5px 5px 12px 3px #111,
      inset -5px -5px 12px 3px #111;
    z-index: 9999;
    ul {
      li {
        text-transform: uppercase;
        font-size: 10px;
        a {
          padding: 8px 5px 10px;
          color: var(--whiteClr);
          display: flex;
          flex-direction: column;
          align-items: center;
          text-decoration: none;
          cursor: pointer;
          svg,
          img {
            fill: currentColor;
            stroke: currentColor;
            width: 22px;
            height: 22px;
          }
          span {
            margin-top: 5px;
          }
          &.active {
            color: var(--primaryClr);
            svg {
              fill: currentColor;
              stroke: currentColor;
            }
          }
        }
      }
    }
  }
}

.live_casino_page {
  // padding-top: 50px;

  // &.ezugi {
  //   background: rgb(35, 31, 32);
  // }
  &.live {
    background: #1d1d27;
    color: #d8d8d8;
    // margin-top: 90px;
  }

  .header-bg {
    // background-color: #e3e2e2;
    .header {
      margin-bottom: 0;
    }

    .headerRight {
      fill: var(--primaryClr);

      .path-stroke {
        stroke: var(--primaryClr);
      }
    }
  }

  // .Toastify__toast-container {
  //   z-index: 9999999 !important;
  //   position: relative;
  // }

  .custom_placeholder_parent {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: rgba(87, 84, 85, 0.84);

    .custom_placeholder_child {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      max-width: 100px;
    }
  }
}

.games-all {
  .casino_games {
    .shadow.rounded.position-relative {
      padding: 0px;
      margin: 10px 0px;
      width: 100%;
    }
  }
}

#Games-select {
  width: 100%;
  font-size: 20px;
  padding: 15px 10px;
  background: #0e1422;
  color: #fff;
  border-radius: 10px;
  border: 1px solid gold;
  margin-bottom: 20px;

  option {
    border-radius: 10px;
    font-size: 12px;
    border-radius: 10px;
    font-size: 20px;
    padding: 10px 0px;
  }
}

.contact-area {
  .panel {
    background: #131b2f;
    padding: 10px 20px;
    border-radius: 5px;

    svg {
      font-size: 30px;
    }
  }
}

.contact_btn {
  width: 100%;
  text-align: left;
  font-size: 20px;
  padding: 10px 10px;
  background: #0a0f19;
  border: 1px solid gold;
  border-radius: 10px;
  display: inline-block;
  color: #fff;

  &:hover {
    color: #fff;
  }
}

.non_cash_balance {
  padding: 10px 10px;
  background: #0e1627;
  margin: 10px 0px 20px 0px;
  border-radius: 5px;

  .non_cash_balance_heading {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    width: 100%;

    .non_cash_balance small {
      font-size: 12px;
      margin: 0px 10px;
      font-weight: 400;
      color: #959595;
    }
  }
}
.changePasswordModal {
  .modal-header {
    .btn-close {
      filter: invert(1);
    }
  }
  .modal-body {
    form {
      .icon {
        position: absolute;
        top: 11px;
        right: 15px;
        svg {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
}
// .fade.changePasswordModal.modal.show .modal-dialog {
//   transform: translate(0, 250px);
// }
.changes_password {
  width: 100%;
  padding: 10px 10px;
  margin: 5px 0px;
  border: 1px solid goldenrod;
  border-radius: 5px;
  color: #fff;
  background: transparent;
}

.fade.changePasswordModal.modal.show .modal-dialog .modal-content {
  background: #18243d;
}
.fade.changePasswordModal.modal.show .modal-dialog .modal-content .btn-close {
  opacity: 1;
}
.fade.changePasswordModal.modal.show
  .modal-dialog
  .modal-content
  .form-control:focus {
  background: transparent !important;
  color: var(--whiteClr) !important;
}

.fade.changePasswordModal.modal.show .btn.btn-primary {
  background: goldenrod;
  border: 0px;
}

.Support_page {
  .footer {
    .footer-links.mb-3 {
      display: none;
    }
  }
}

// Media Quary Start ======>>
@media only screen and (max-width: 1199.98px) {
  .main .mainBanner .slick-slide .content h2 {
    font-size: 2rem;
    max-width: 250px;
  }

  .main .Live_casino_tabs .top_Sec {
    grid-template-columns: repeat(3, 1fr);
  }

  .main .mainBanner .slick-slide .content p {
    font-size: 1rem;
    max-width: 300px;
  }
}

@media only screen and (max-width: 767.98px) {
  .valuesDeposit {
    align-items: center;
    h3 {
      font-size: 16px;
    }
    .copyValue {
      font-size: 15px !important;
    }
  }
  .searchGames {
    p {
      display: none;
    }
  }
  .cards1 {
    width: 25%;
  }

  .withdraw_btn {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .agent-head {
    justify-content: space-between;
    margin-top: 0 !important;
    .social-icon {
      width: 20%;
      margin: 2%;
    }
  }
  .header {
    .navbar {
      .navbar-toggler {
        border-radius: 0.375rem;
        line-height: 16px;
        padding: 0 0.15rem;
        display: none;
        right: -37px;
        top: 16px;
      }
    }
    &.aftrlgn {
      .logo {
        margin-left: 0;
        margin-right: 0;
      }
    }
    .headerRight {
      .menu-btn {
        margin-left: 0;
      }
      .funds {
        ul {
          margin-right: 0px;
          padding: 0px !important;
          li {
            margin: 0 4px !important;
            a {
              color: var(--whiteClr);
              display: block;
              border-radius: 22px;
              padding: 3px 10px !important;
              span {
                padding-left: 2px;
              }
            }
          }
          li:after {
            display: none;
          }
        }
      }
      ul {
        li {
          margin: 0 2px;
        }
      }
    }
  }
  .account-statement button {
    .calendar_icon {
      svg {
        display: block;
      }
    }
  }
  .Qr-code-scanner {
    display: flex;
    justify-content: center;
    img {
      width: 40%;
    }
  }

  .Live_Ezugi_Iframe {
    margin-top: 5px;
  }
  .header {
    .logo {
      img {
        width: 90px;
        height: auto;
      }
    }
  }
  .Spribe_Games .Provider_Games a {
    width: 50%;
    display: flex;
    justify-content: center;
  }
  .withdraw_btn a.btn_primary.btn-primary {
    margin-left: 0;
    margin-top: 12px;
    width: 85%;
  }
  .loading_img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    // margin-top: 10%;
    background: #000;
  }
  .loading_img img {
    width: 100%;
    height: 40%;
  }
  .listings {
    .left-arrows {
      display: none;
    }
  }
  .Live_casino_tabs {
    .live_heading {
      img {
        width: 20% !important;
      }
    }
    .live_heading.ev-heading {
      img {
        width: 30% !important;
      }
    }
  }

  .slick-dots {
    li {
      button {
        height: 2px !important;
        width: 20px !important;
      }
      li.slick-active {
        button {
          height: 2px !important;
          width: 20px !important;
        }
      }
    }
  }
  .main .mainBanner .slick-slide .content {
    width: 80%;
  }

  .main .mainBanner .slick-slide .content h2 {
    font-size: 1.5rem;
  }

  .main .mainBanner .slick-slide .content p {
    font-size: 0.85rem;
  }

  .footer {
    padding: 0px 0 30px;
  }

  // .Live_games_row {
  //   justify-content: center;
  //   align-items: center;

  //   .Live_games_col {
  //     width: auto;
  //   }
  // }
}
.live_heading a {
  color: #000;
}
@media only screen and (max-width: 575.98px) {
  .main .Live_casino_tabs .nav-tabs {
    background: #0e1422;
    padding-bottom: 0px;
  }

  .main .Live_casino_tabs .top_Sec {
    grid-template-columns: repeat(3, 1fr);
  }

  #basic-navbar-nav .me-auto.nav .nav-link {
    display: none;
  }

  .live_heading {
    padding: 10px 0px;
    margin: 5px 0px;
    font-size: 26px;
  }

  .main .Live_casino_tabs .nav-tabs .nav-item .nav-link {
    background-position: 32px 10px;
    background-size: 30px 30px;
    padding: 45px 5px 5px 5px;
    width: 95px;
    white-space: nowrap;
  }

  .live_heading img {
    width: 110px;
  }

  .Spribe_Games .shadow.rounded.position-relative {
    padding: 0px 10px;
  }

  .shadow.rounded.position-relative {
    padding: 0px 0px;
  }
  vailable .Payments_Games .shadow.rounded.position-relative {
    width: 85px !important;
  }

  .Provider_Games .shadow.rounded.position-relative {
    width: 120px !important;
    padding: 0 !important;
  }

  .Provider_Games .shadow.rounded.position-relative img {
    width: 100%;
    height: auto;
  }

  .listings.pt-5 {
    padding-top: 15px !important;
  }

  .footer .footer-links ul li {
    margin: 5px 0;
  }

  #payment_page {
    // padding-top: 100px;
    padding-bottom: 50px;
  }

  .games-all .shadow.rounded.position-relative {
    min-width: calc(100% - 0px);
    width: calc(100% - 0px) !important;
  }

  // .Toastify__toast-container {
  //   z-index: 9999999 !important;
  //   position: relative;
  // }

  .listings .filter .btn-outline-primary {
    width: 100%;
    padding: 10px 0px;
  }

  .tabs-area {
    ul {
      li {
        .nav-link {
          padding: 5px 10px;
        }
      }
    }
  }

  .tabs-area {
    .tab-content {
      form {
        padding: 20px 30px 45px;
        text-align: center;
      }
    }
  }

  .main {
    .sectionTitle {
      margin-top: 0px !important;
    }
  }

  .main {
    .profile-img {
      padding-top: 30px;
    }
  }

  .choose_file h4 {
    left: 35%;
  }

  .mstrpassword {
    max-width: 28ch;
  }

  #Games-select {
    option {
      border-radius: 10px;
      font-size: 12px;
    }
  }

  .main .profile-img img {
    max-width: 100px;
    width: 100px;
    height: 100px;
  }

  .main .profile-img {
    padding-top: 0px;
  }

  .account_details {
    text-align: center;
  }

  .Payments_Games .shadow.rounded.position-relative img {
    width: 200px;
    height: auto;
  }
}
// .Live_casino_tabs {
//   position: relative;
//   img.grey-img {
//     display: block;
//     top: 8px;
//     left: 30px;
//     width: 55px;
//     position: absolute;
//   }
//   img.color-img {
//     display: none;
//     top: 8px;
//     left: 30px;
//     width: 55px;
//     position: absolute;
//   }
//   .active {
//     img.color-img {
//       position: absolute;
//       width: 55px;
//       display: block;
//       top: 8px;
//       left: 30px;
//     }
//     img.grey-img {
//       display: none;
//     }
//   }
//   .tab-content > .tab-pane.fade {
//     display: block;
//     opacity: 1 !important;
//   }
// }

.main {
  .Live_casino_tabs {
    .nav-tabs {
      .nav-item {
        .nav-link.active {
          background-image: url("../public/images/Roulette_Icons.png");
          border: 0 !important;
        }
        .nav-link {
          background-image: url("../public/images/games-image/Roulette_Icons.png");
          &:hover {
            border: 0 !important;
          }
        }
        #uncontrolled-tab-example-tab-Baccarat.active {
          background-image: url("../public/images/Baccarat_Icons.png");
        }
        #uncontrolled-tab-example-tab-Baccarat {
          background-image: url("../public/images/games-image/Baccarat_Icons.png");
        }
        #uncontrolled-tab-example-tab-Andar\ Bahar.active {
          background-image: url("../public/images/Andar_bahar_Icons.png");
        }
        #uncontrolled-tab-example-tab-Andar\ Bahar {
          background-image: url("../public/images/games-image/Andar_bahar_Icons.png");
        }
        #uncontrolled-tab-example-tab-Poker.active {
          background-image: url("../public/images/Poker_Icons.png");
        }
        #uncontrolled-tab-example-tab-Poker {
          background-image: url("../public/images/games-image/Poker_Icons.png");
        }
        #uncontrolled-tab-example-tab-Blackjack.active {
          background-image: url("../public/images/Blackjack_icons.png");
        }
        #uncontrolled-tab-example-tab-Blackjack {
          background-image: url("../public/images/games-image/Blackjack_icons.png");
        }
        #uncontrolled-tab-example-tab-TeenPatti.active {
          background-image: url("../public/images/Teenpatti.png");
        }
        #uncontrolled-tab-example-tab-TeenPatti {
          background-image: url("../public/images/games-image/Teenpatti.png");
        }
        #uncontrolled-tab-example-tab-32Card.active {
          background-image: url("../public/images/casino.png");
        }
        #uncontrolled-tab-example-tab-32Card {
          background-image: url("../public/images/games-image/casino.png");
        }
        #uncontrolled-tab-example-tab-Seven.active {
          background-image: url("../public/images/seven.png");
        }
        #uncontrolled-tab-example-tab-Seven {
          background-image: url("../public/images/games-image/seven.png");
        }
        #uncontrolled-tab-example-tab-Dices.active {
          background-image: url("../public/images/dices.png");
        }
        #uncontrolled-tab-example-tab-Dices {
          background-image: url("../public/images/games-image/dices.png");
        }
        #uncontrolled-tab-example-tab-BetNumber.active {
          background-image: url("../public/images/bet_on_numbers.png");
        }
        #uncontrolled-tab-example-tab-BetNumber {
          background-image: url("../public/images/games-image/bet_on_numbers.png");
        }
        #uncontrolled-tab-example-tab-gameshows.active {
          background-image: url("../public/images/microphone.png");
        }
        #uncontrolled-tab-example-tab-gameshows {
          background-image: url("../public/images/games-image/microphone-bw.png");
        }
      }
    }
  }
}

.live_heading-center {
  text-align: center;
  color: #ffc400;
}

.Live_casino_tabs {
  .live_heading {
    img {
      width: 10%;
    }
  }
  .live_heading.ev-heading {
    img {
      width: 13%;
    }
  }
}

.profit_loss_heading {
  color: #fcc200;
  font-size: 1rem;
  font-weight: 600;
  border: 1px solid #fcc200;
  background: #343b4e !important;
}

@media only screen and (orientation: landscape) {
  // .header {
  //   display: none;
  // }
  #basic-navbar-nav {
    .provider-img {
      width: 60px;
    }
  }
}
.Qr-code-scanner {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  p {
    font-weight: bold;
    padding-top: 5px;
    font-size: 17px;
    margin-bottom: 0;
  }

  img {
    width: 25%;
  }
}

.Toastify .Toastify__toast-container {
  z-index: 9999999999999999 !important;
  // right: inherit;
}

.country-name {
  color: #000;
}
.country-list {
  li {
    display: table;
  }
}

.loading_img img {
  width: 100%;
}
.mainFooter {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgb(55, 65, 81);
  .footer-content {
    display: flex;
    gap: 8rem;
    .footer-nav {
      h3 {
        letter-spacing: 0.05em;
        font-size: 0.75rem;
        font-weight: 700;
        color: var(--primaryClr);
      }
      ul {
        margin-top: 1rem;
        li a {
          font-size: 1rem;
          color: rgb(209 213 219/1);
          padding-bottom: 16px;
          display: block;
        }
      }
      .providerImg {
        margin: 0 auto;
        width: 80px;
        margin-top: 20px;
        img {
          width: 80px;
          margin: 0 auto;
        }
      }
    }
  }
}
.general-foot-content {
  border-bottom: 1px solid rgb(55, 65, 81);
  p {
    color: rgba(156 163 175/1);
    margin: 0 !important;
    padding: 20px 0;
    text-align: center;
    a {
      color: var(--primaryClr);
      text-decoration: underline;
    }
  }
}
.copy-right-social-icon {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  .copyContent {
    p {
      color: rgba(156 163 175/1);
      margin: 0;
      span {
        color: var(--primaryClr);
        font-weight: bold;
      }
    }
  }
  .socila-icons {
    display: flex;
    img {
      width: 20px;
      margin-right: 10px;
    }
  }
}
@media only screen and (max-width: 375px) {
  .header {
    .headerRight {
      .funds {
        ul {
          li {
            a {
              display: flex;
              border-radius: 22px;
              font-size: 12px;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
.loaderSec {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  img {
    width: 250px;
  }
}
.heading_provider {
  display: flex;
  justify-content: end;
  p {
    background: #ffc500;
    padding: 10px;
    border-radius: 5px;
    color: black;
    white-space: nowrap;
    cursor: pointer;
  }
}
.contact-social ul {
  display: flex;
  li img {
    width: 60px;
    height: auto;
    margin: 0 15px;
  }
}
.slots-section {
  margin: 25px auto 0;
  width: 95%;
  .game-heading {
    align-items: center;
    display: flex;
    margin-bottom: 24px;
    img {
      margin-right: 10px;
      width: 30px;
    }
    span {
      color: #fff;
      font-size: 24px;
      font-weight: 600;
    }
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    padding: 0;
    li {
      background-color: transparent;
      display: block;
      height: auto;
      margin: 0.5%;
      overflow: hidden;
      padding: 0;
      position: relative;
      width: 19%;
      &:hover .casino_overlay {
        inset: 0 0 0 0;
        img {
          height: 80px !important;
          width: 80px !important;
        }
      }
      img {
        border-radius: 8px;
        width: 100% !important;
      }
      .casino_overlay {
        align-items: center;
        background: linear-gradient(
          0deg,
          rgba(37, 35, 152, 0.49),
          rgba(37, 35, 152, 0.49)
        );
        border-radius: 10px;
        content: "";
        display: flex;
        inset: 0 0 0 100%;
        justify-content: center;
        position: absolute;
        transition: all 0.9s;
        img {
          border-radius: 10px;
          height: 0;
          width: 0 !important;
        }
      }
    }
  }
}
.new_table {
  thead {
    th {
      padding: 5px;
      color: #fff;
    }
    background: var(--secondaryClr2);
  }
  tbody {
    background: #2b2d30;
    color: #fff;
    tr td {
      border: 0;
    }
  }
  background: var(--headClr);
  padding: 6px;
}
.primary_card {
  border-radius: 10px;
  border: 1px solid #d58b2f;
  .stats-card {
    display: flex;
    ul {
      border-right: 1px solid #353c4b;
      width: 50%;
      &:last-child {
        border: 0;
      }
      li {
        // height: 50px;
        display: flex;
        justify-content: space-between;
        padding: 10px;
        border-bottom: 1px solid #353c4b;
        font-weight: 700;
        span {
          width: 50%;
          // font-size: 13px;
          display: block;
          font-weight: normal;
        }
      }
    }
  }
}

.heading {
  display: flex;
  justify-content: space-between;
  background: var(--headClr);
  padding: 10px;
  align-items: center;

  h4 {
    margin: 0;
    color: #fff;
  }
}
.main_card {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .progress {
    background: #393939;
  }
}
.cards {
  span {
    color: orange;
    margin-left: 4px;
  }
  border: 1px solid var(--primaryClr);
  padding: 15px;
  border-radius: 10px;

  width: 32.33%;
  margin: 0.5%;
}
.amount_data {
  justify-content: space-between;
}

.cancel_bonus {
  background: red;
  padding: 1px 2px;
  font-size: smaller;
  cursor: pointer;
  border-radius: 5px;
  text-transform: uppercase;
}

.amount_data {
  .status {
    color: green;
  }
  .status_claimed {
    color: #f1c232;
  }
  .staus_pending {
    color: #f1c232;
  }
  .staus_expired {
    color: #cc0000;
  }
  .staus_canceled {
    color: #cc0000;
  }
}
.referral_card_main {
  display: flex;
  justify-content: normal !important;
  margin-top: 10px !important;
  gap: 15px;
  .referrals_cards {
    margin-top: 10px;
    width: 100%;
    background: #333334;
    border-radius: 5px;
    flex: 1 1;
    h5 {
      font-size: 1rem;
    }
    .referr_user {
      display: flex;
      gap: 10px;
      svg {
        fill: var(--primaryClr);
      }
      p {
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 0;
      }
    }
  }
}
.referral_link {
  background-color: var(--headClr);
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 6px 20px;
  h5 {
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 0;
    margin-right: 20px;
  }
  div {
    background: #333334;
    border-radius: 5px;
    color: #e7e4e4;
    flex: 1 1;
    padding: 8px 15px;
  }
}
.referr_tabs ul {
  border-bottom: 0;
  gap: 20px;
  button {
    background: #333334 !important;
    border: 0 !important;
    color: #fff !important;
    padding: 8px 35px;
  }
}
.referr_table {
  color: #fff;
  thead {
    background: #333334;
    border: 0;
    th {
      border: 0;
    }
  }
  tbody {
    background-color: #000;
    td {
      border-color: #333;
    }
  }
}

.gatewaySection {
  max-width: 800px;
  margin: 40px auto 15px;
  ul.nav-tabs {
    border: 0;
    margin-bottom: 0 !important;
    li {
      width: 50%;
      button {
        background: linear-gradient(0deg, #031c2a 0%, #213040 100%);
        border-radius: 15px 15px 0px 0px;
        border: 0;
        width: 100%;
        color: #fff;
        &.active {
          background: #facb2f;
          border-radius: 15px 15px 0px 0px;
          color: #fff;
        }
      }
    }
  }
  .tab-content {
    background: #0c1220;
    padding: 20px 50px;
    .tab-pane.active {
      display: flex;
      flex-wrap: wrap;
      .cards1 {
        cursor: pointer;
        background: linear-gradient(0deg, #031c2a 0%, #213040 100%);
        border: 0.5px solid #414141;
        box-sizing: border-box;
        border-radius: 10px;
        width: 31.3333%;
        float: left;
        margin: 1% 1%;
        .card-icon {
          padding: 12px 5px 4px 5px;
          font-size: 12px;
          border-bottom: 0.5px solid #414141;
          text-align: center;
          img {
            width: 80px;
          }
        }
        p {
          padding: 11px 4px;
          font-size: 14px;
          font-size: 12px;
          margin: 0;
          text-align: center;
        }
      }
    }
  }
}
.bonus-space {
  margin-left: 10px;
}

.gatewayForm {
  width: 85%;
  margin: 15px auto 0;
  input {
    height: 50px;
    background-color: transparent;
    border: 1px solid var(--primaryClr);
    color: var(--whiteClr);
    &:focus {
      background-color: transparent;
      border: 1px solid var(--primaryClr);
      color: var(--whiteClr);
    }
  }
  button {
    padding: 9px 30px;
    font-size: 14px;
    text-transform: uppercase;
    background: var(--primaryClr);
    color: var(--blackClr);
    border-color: var(--primaryClr);
    font-weight: 600;
  }
}
.searchGames1 {
  background: transparent;
  .modal-dialog {
    .modal-content {
      color: #000;
      border: 2px solid goldenrod;
      .modal-header {
        background: var(--headClr);
        color: #fff;
        .btn-close {
          filter: invert(1);
        }
      }
      .modal-body {
        background: #000;
        color: #fff !important;
      }
      input {
        color: #000000 !important;
      }
    }
  }
}
.searchGames {
  display: flex;
  // margin-inline: 5px !important;
  border-radius: 16px;
  background: #000000;
  border: 1px solid goldenrod;
  align-items: center;
  justify-content: center;
  padding: 5px 8px;
  cursor: pointer;
  p {
    margin-right: 8px;
    margin-bottom: 0;
  }
}
.GamesShow {
  display: flex;
  flex-wrap: wrap;
  gap: 6%;
  justify-content: center;
  align-items: center;
  .displayGames {
    width: 40%;
    margin-bottom: 30px;
    box-shadow: 0px 3px 10px 10px #334155;
  }
}

.whtasapp-all-page {
  position: fixed;
  right: 24px;
  bottom: 80px;
  display: flex;
  flex-direction: column;
  z-index: 999;
  img,
  svg {
    width: 45px;
    height: 45px;
    margin-bottom: 10px;
    border-radius: 0;
  }
}

.spt-bet-slip {
  bottom: 56px;
}
.valuesDeposit {
  border: 1px var(--primaryClr) solid;
  margin-top: 20px;
  color: #c39600;
  background: #ffffff;
  padding: 10px;
  border-radius: 8px;
  display: flex;
  /* width: 50%; */
  justify-content: center;
  h3 {
    font-weight: 400;
  }
  .copyValue {
    font-size: 20px;
    margin-left: 30px;
    text-align: center;
    div {
      font-size: 8px;
      font-weight: 700;
    }
  }
}
.qrSize {
  width: 250px;
}

.WithdrawPageSection {
  .WithDrawSection.tabs-area {
    width: 760px;
    ul {
      border: 0;
      background: #2c2f3d;
      li {
        button {
          color: var(--whiteClr) !important;
          border-radius: 0 !important;
          text-align: center;
          font-weight: 400;
          border: 1px solid #444;
          background: rgb(44, 47, 61);
          background: linear-gradient(
            100deg,
            rgba(44, 47, 61, 1) 0%,
            rgba(57, 67, 91, 1) 100%
          );
          padding: 10px !important;
          &.active {
            background: rgb(6, 99, 177);
            background: linear-gradient(
              100deg,
              rgb(43 133 243) 0%,
              rgb(131 175 228) 100%
            ) !important;
          }
        }
      }
    }
    .tab-content {
      padding-bottom: 0 !important;

      .WithDrawBankDetails {
        background-color: #021622;
        background-color: #021622;
        background: url("./assets/images/banner/withdrawbackground.jpg") #021622;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        padding: 10px;
        .heading {
          display: none;
        }
        .playerBankDetails {
          input,
          select {
            height: 45px;
            border-radius: 3px !important;
          }
          input {
            background: transparent !important;
            color: var(--whiteClr) !important;
            border: 1px solid var(--whiteClr) !important;
            &::placeholder {
              color: var(--whiteClr);
            }
            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
              box-shadow: 0 0 0 30px var(--whiteClr) inset !important;
              -webkit-text-fill-color: var(--blackClr) !important;
            }
          }
          button.btn-primary {
            background: rgb(6, 99, 177);
            background: linear-gradient(
              100deg,
              rgba(6, 99, 177, 1) 0%,
              rgba(40, 131, 239, 1) 100%
            );
            border-radius: 5px !important;
            border: 0;
            font-weight: 400;
            height: 45px;
          }
        }
        h4 {
          font-size: 18px;
          margin-bottom: 15px;
        }
        .WithdrawTable {
          thead {
            border: 1px solid #7f898f;
            border-radius: 3px !important;
            th {
              white-space: nowrap;
            }
          }
          tbody {
            tr {
              td {
                color: var(--whiteClr) !important;
                padding: 12px 0 !important;
                line-height: 30px;
                input[type="checkbox"] {
                  display: none;
                }
                label {
                  display: block;
                  margin: 0.2em;
                  cursor: pointer;
                  padding: 0.2em;
                }
                button {
                  margin-right: 8px;
                }
              }
              th,
              td {
                color: var(--whiteClr) !important;
                padding: 12px !important;
                line-height: 30px;
                background: transparent !important;
                white-space: nowrap;
              }
            }
          }
        }
      }

      .WithdrawRequestSection {
        ul.nav-tabs {
          margin-top: 10px;
          background-color: var(--whiteClr) !important;
          button {
            background: var(--whiteClr) !important;
            color: var(--blackClr) !important;
            text-align: center !important;
            &.active {
              background: #169f00 !important;
              color: var(--whiteClr) !important;
            }
          }
        }
        .tab-content {
          background: url("./assets/images/banner/withdrawbackground.jpg")
            #021622;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          padding: 10px;
          input {
            background: transparent !important;
            color: var(--whiteClr) !important;
            border: 1px solid var(--whiteClr) !important;
            &::placeholder {
              color: var(--whiteClr);
            }
            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
              box-shadow: 0 0 0 30px var(--whiteClr) inset !important;
              -webkit-text-fill-color: var(--blackClr) !important;
            }
          }
        }

        .WithdrawRequestBankSection {
          background-color: #021622;
          padding: 10px;
          .WithdrawTable {
            border: 0;
            thead {
              background-color: #2c2f3d;
              border: 0;
              border-radius: 3px !important;
              th {
                border: 0;
                color: var(--whiteClr);
                white-space: nowrap;
                background: transparent;
              }
            }
            tbody {
              tr {
                background: transparent;
                th {
                  background: transparent;
                  line-height: 40px;
                  color: var(--whiteClr);
                }
                td {
                  color: var(--whiteClr) !important;
                  padding: 12px 0.5rem !important;
                  line-height: 30px;
                  background: transparent;
                  button {
                    margin-right: 8px !important;
                  }
                }
              }
            }
          }
        }
        .sectionTitle {
          color: var(--whiteClr) !important;
          margin: 18px 0 25px !important;
          position: relative;
          text-align: center !important;
          &::after {
            position: absolute;
            content: "";
            border: 1px solid var(--whiteClr);
            left: 50%;
            transform: translate(-50%, -50%);
            width: 120px;
            bottom: 0;
          }
        }
        button.btn-primary {
          background: rgb(6, 99, 177);
          background: linear-gradient(
            100deg,
            rgba(6, 99, 177, 1) 0%,
            rgba(40, 131, 239, 1) 100%
          );
          border-radius: 5px !important;
          border: 0;
          font-weight: 400;
          height: 45px;
          margin-right: 10px;
        }
      }
    }
  }
}

.updateBankDeatils {
  z-index: 999999;
  .modal-header {
    background-color: var(--headClr);
    color: var(--whiteClr);
    border: 0;
    button {
      filter: invert(1);
    }
  }
  .modal-body {
    background: #021420;
    input {
      background: transparent !important;
      color: var(--whiteClr) !important;
      border: 1px solid var(--whiteClr) !important;
      &::placeholder {
        color: var(--whiteClr);
      }
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        box-shadow: 0 0 0 30px var(--whiteClr) inset !important;
        -webkit-text-fill-color: var(--blackClr) !important;
      }
    }
    button.btn-primary {
      background: rgb(6, 99, 177);
      background: linear-gradient(
        100deg,
        rgba(6, 99, 177, 1) 0%,
        rgba(40, 131, 239, 1) 100%
      );
      border-radius: 5px !important;
      border: 0;
      font-weight: 400;
      height: 35px;
      margin: 0 auto;
      width: 85px;
      text-align: center;
      font-size: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.WithdrawGif {
  img {
    animation: moving 3s infinite linear;
    margin-left: 20px;
    width: 365px;
    @keyframes moving {
      0% {
        left: 0;
      }
      50% {
        left: 0;
        -webkit-transform: translateY(15px);
        transform: translateY(15px);
      }
      100% {
        left: 0;
      }
    }
  }
}

.WithdrawTable tbody tr td input[type="checkbox"] + label {
  display: block;
  margin-bottom: 0;
  cursor: pointer;
  padding: 0.2em;
}
.WithdrawTable tbody tr td input[type="checkbox"] {
  display: none;
}
.WithdrawTable tbody tr td input[type="checkbox"] + label:before {
  content: "\2714";
  border: 0.1em solid #fff;
  border-radius: 0.2em;
  display: inline-block;
  width: 2em;
  height: 2em;
  padding-left: 0.5em;
  padding-bottom: 0.3em;
  vertical-align: bottom;
  color: transparent;
  transition: 0.2s;
}
.WithdrawTable tbody tr td input[type="checkbox"]:checked + label:before {
  background-color: MediumSeaGreen;
  border-color: MediumSeaGreen;
  color: #fff;
}

.WithdrawPageSection
  .WithDrawSection.tabs-area
  .tab-content
  .WithdrawRequestSection
  .tab-content
  input:disabled {
  background: #415059 !important;
  border: 0 !important;
  cursor: no-drop !important;
}

.betslip_popup {
  .modal-body {
    background: var(--bodyClr);
    border-radius: 5px 5px 0 0;
    @media (max-width: 767.98px) {
      padding: 0;
    }
    .main {
      @media (max-width: 767.98px) {
        padding-bottom: 0;
      }
      .container {
        @media (max-width: 767.98px) {
          padding: 0;
        }
        .table {
          tr {
            th {
              @media (max-width: 767.98px) {
                min-width: 100px;
              }
              &:first-child {
                @media (max-width: 767.98px) {
                  min-width: 40px;
                }
              }
            }
          }
        }
      }
    }
  }
  .modal-footer {
    background: var(--blackClr);
    border-top: 0;
    border-radius: 0 0 5px 5px;
    .btn-primary {
      background: #777 !important;
      border-color: #777 !important;
    }
  }
}
.banner-btn {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  .btn {
    width: 100%;
    height: 100%;
    border-radius: 0;
    padding: 0;
    margin: 0;
    opacity: 0 !important;
  }
}
.live_casino_page {
  .ezugi_iframe,
  .exchange_iframe {
    height: calc(100vh - 85px);
    overflow-y: hidden;
    @media (max-width: 767.98px) and (min-width: 768px) {
      height: calc(100vh - 116px);
    }
    @media (max-width: 767.98px) {
      height: calc(100vh - 101px);
    }
    iframe {
      height: calc(100vh - 85px);
      @media (max-width: 767.98px) and (min-width: 768px) {
        height: calc(100vh - 116px);
      }
      @media (max-width: 767.98px) {
        height: calc(100vh - 101px);
      }
      @media only screen and (min-device-width: 280px) and (max-device-width: 767.98px) {
        height: calc(100vh - 101px);
        height: calc(var(--vh, 1vh) * 100 - 101px);
      }
    }
  }
}

.game-modal {
  .modal-dialog {
    max-width: 400px !important;
  }
  .modal-content {
    background: var(--bodyClr);
    border-radius: 10px;
    .modal-body {
      background: var(--bodyClr);
      padding: 20px;
      border-radius: 10px;
      .content {
        text-align: center;
        h5 {
          color: var(--whiteClr);
          font-weight: 700;
          margin-bottom: 1rem;
        }
        p {
          color: var(--whiteClr);
          font-size: 16px;
          span {
            font-size: 13px;
            opacity: 0.8;
          }
        }
        .play-btn {
          padding-top: 20px;
          margin-top: 30px;
          border-top: 1px solid #525c71;
          div {
            background: var(--whiteClr);
            color: var(--secondaryClr);
            font-size: 16px;
            display: flex;
            align-items: center;
            width: fit-content;
            margin: 0 auto;
            padding: 10px 20px;
            border: 1px solid var(--whiteClr);
            border-radius: 5px;
            transition: all 0.3s;
            .img-box {
              background: var(--secondaryClr);
              // width: 25px;
              // height: 25px;
              // line-height: 25px;
              text-align: center;
              margin-left: 10px;
              border-radius: 50%;
              img {
                width: 12px;
                filter: invert(0.6);
                transition: all 0.3s;
              }
            }
            &:hover,
            &:focus {
              background: #525c71;
              color: var(--whiteClr);
              border-color: #525c71;
            }
          }
        }
      }
    }
  }
}
.MaintainSection {
  display: flex;
  align-items: center;
  height: 100vh;
  padding: 0 40px;
  .MaintainSectionContent {
    width: 50%;

    .MaintainSection__title {
      font-size: 40px;
      line-height: 17px;
    }
    p.maintainword {
      text-transform: uppercase;
      color: var(--primaryClr);
      font-weight: 800;
      font-size: 50px;
      position: relative;
      margin-bottom: 70px;
      &::before {
        width: 55px;
        height: 5px;
        display: block;
        content: "";
        position: absolute;
        bottom: -2px;
        left: 30%;
        margin-left: -14px;
        background-color: var(--primaryClr);
      }
      &::after {
        width: 410px;
        height: 1px;
        display: block;
        content: "";
        position: relative;
        margin-top: 6px;
        left: 82px;
        margin-left: -78px;
        background-color: var(--primaryClr);
      }
    }
    p.backsoon {
      font-size: 40px;
      color: #ffffff;
      line-height: 13px;
      font-weight: 600;
      margin-bottom: 30px;
    }
    p {
      font-size: 14px;
    }
  }
  .MaintainImg {
    width: 50%;
    margin: 0 auto;
    text-align: center;
    img {
      width: 500px;
    }
  }
}

.marqu {
  background: var(--bodyClr);
  display: flex;
  align-items: center;
  position: relative;
  z-index: 9;
  justify-content: space-between;
  padding: 4px 10px;
  img {
    width: 35px;
  }
  marquee {
    width: 100%;
  }
  span {
    position: absolute;
    width: 35px;
    height: 30px;
    top: 0;
    right: 0;
    background: #273344;
    display: flex;

    color: #fff;
    justify-content: center;
    align-items: center;
    font-size: 15px;
  }
  button {
    border-radius: 30px;
    padding: 7px 20px;
    font-size: 14px;
  }
}
