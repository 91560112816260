@import "bootstrap/dist/css/bootstrap.min.css";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300@400;500;700&display=swap");

::-webkit-scrollbar {
  height: 12px;
  width: 12px;
  background: #4e4e4e;
  -webkit-border-radius: 1ex;
}

::-webkit-scrollbar-thumb {
  background: #373738;
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}

::-webkit-scrollbar-corner {
  background: #4e4e4e;
  -webkit-border-radius: 1ex;
}

img {
  max-width: 100%;
}

.header-left #basic-navbar-nav .nav-link {
  color: #fff;
}

.header-left {
  display: flex;
  justify-content: center;
  align-items: center;
}

.live_heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 30px;
  font-weight: 900;
  background: -webkit-linear-gradient(
    top,
    #8f6b29,
    #fde08d,
    #df9f28
  ) !important;
  background-clip: text !important;
  font-family: Arial, Arial, Tahoma, Serif;
  margin: 25px 0px 20px 0px;
}

.Evolution_Games {
  justify-content: start;
  position: relative;
  overflow-x: scroll;
  scrollbar-width: none;
  scrollbar-color: #132d44 #1c2634;
}
.Evolution_Games.no-slides {
  display: flex;
}
.listings {
  position: relative;
}
.listings .left-arrows {
  position: absolute;
  display: block;
  width: 35px;
  height: 35px;
  top: 50%;
  transform: translateY(-50%);
  left: 1px;
  z-index: 8;
}
.listings .right-arrow {
  left: auto !important;
  right: 0 !important;
}
.listings .left-arrows svg {
  height: 35px;
  width: 35px;
  color: gold;
}
.Evolution_Games .slick-list {
  width: calc(100% - 80px);
  margin-left: 40px;
}
.Evolution_Games .slick-prev {
  left: 6px !important;
  background: url("../src/assets/images/icons/left-arrow-icon.svg") !important;
}
.Evolution_Games .slick-next {
  right: 12px !important;
  background: url("../src/assets/images/icons/right-arrow-icon.svg") !important;
}
.Evolution_Games .slick-prev,
.Evolution_Games .slick-next {
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
  width: 30px;
  height: 30px;
  opacity: 0.7;
}
.Evolution_Games .slick-prev:hover,
.Evolution_Games .slick-next:hover {
  opacity: 1 !important;
}
.Evolution_Games .slick-prev:before,
.Evolution_Games .slick-next:before {
  content: none;
}
.Evolution_Games .slick-track {
  margin-left: initial;
}
.Provider_Games .shadow.rounded.position-relative {
  width: 265px;
  padding: 0px 10px;
  box-shadow: 0px 0px 0px #000 !important;
  text-align: center;
}
.Payments_Games .shadow.rounded.position-relative {
  width: 260px;
  padding: 0px 10px;
  box-shadow: 0px 0px 0px #000 !important;
  text-align: center;
}
.shadow.rounded.position-relative {
  /* width: 285px; */
  padding: 0px 10px;
  box-shadow: 0px 0px 0px #000 !important;
  text-align: center;
}
.text {
  font-size: 26px;
  font-weight: 900;
  margin: 0;
  color: var(--primaryClr);
  font-family: Arial, Arial, Tahoma, Serif;
}

.Provider_Games {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background: #0a0f19;
  padding: 10px;
  border-radius: 10px;
}

.Provider_Games .shadow.rounded.position-relative img {
  margin: 0px auto;
  width: 55%;
}

.Payments_Games {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.text-left {
  text-align: center;
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 400;
}

.reference_number {
  border-top: 1px dashed goldenrod;
}

.balance-amount.mb-1 {
  text-align: center;
}

._container_1ib6x_1 {
  bottom: 0;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 90px !important;
  width: 100%;
}

#scroller {
  margin-top: 90px;
}

.slick-next {
  right: 15px !important;
}

.slick-prev {
  left: 15px !important;
  z-index: 9;
}

#dropdown-basic {
  width: 100%;
  text-align: left;
  font-size: 13px;
  padding: 8px 30px 8px 10px;
  /* background: #0a0f19; */
  /* border: 1px solid gold; */
  border-radius: 10px;
}

.Support_show.dropdown-menu.show {
  width: 100% !important;
  font-size: 18px !important;
  background: #0a0f19 !important;
  box-shadow: 0px 5px 30px #00000069;
}

.Support_show.dropdown-menu.show .dropdown-item {
  font-size: 20px;
  color: #fff;
  padding: 10px 10px;
}

.Support_show.dropdown-menu.show .dropdown-item .dropdown-item:focus,
.dropdown-item:hover {
  background-color: #0a0f19 !important;
  color: #c49907 !important;
  border: 1px solid goldenrod !important;
  border-radius: 5px;
}

#dropdown-basic::after {
  position: absolute;
  right: 20px;
  top: 50%;
}

.support_icons {
  width: 26px;
  height: 26px;
}

.profile-info.mt-4 {
  padding: 20px;
  background: #0e1422;
  border-radius: 5px;
  box-shadow: 0px 1px 20px #00000063;
  margin-bottom: 20px;
}

.mstrpassword {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 40ch;
}

.choose_file {
  background: transparent;
  color: #ffffff;
  padding: 0;
  border: 1px solid goldenrod !important;
  position: relative;
  border-radius: 0.375rem;
}
.choose_file input[type="file"] {
  border: 0;
}
.choose_file h4 {
  display: none;
}
/* #formBasicName {
  opacity: 0;
} */

.choose_file h4 {
  position: absolute;
  top: 28%;
  left: 45%;
}

.telegram_icon {
  color: blue;
}

.whatsapp_icon {
  color: green;
}
.user_name {
  color: #fff;
}
.user_name:hover {
  color: #fff;
}
.social_icons {
  width: 32px;
  border-radius: 50px;
}

#profile_page .table-responsive .table tr {
  border-color: #1e2430;
  border-style: solid;
  border-width: 1px !important;
}

.text-center.mb-4 {
  font-weight: 100;
  color: goldenrod;
  background: #0e1422;
  border-radius: 5px;
  padding: 10px 0px;
}
.Toastify {
  position: relative !important;
  z-index: 9999999999 !important;
}
img.provider-img {
  width: 78px;
}

.main .Live_casino_tabs .nav-tabs .nav-item .nav-link.active {
  background-image: ("./public/images/Roulette_Icons.png");
}
.main .Live_casino_tabs .nav-tabs .nav-item .nav-link {
  background-image: ("./public/images/game-image/Roulette_Icons.png");
}
.loading_img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}
.loading_img img {
  width: 500px;
  height: 100vh;
}
.privacy_policy {
  padding-bottom: 71px;
}

.withdraw_btn a.btn_primary.btn-primary {
  padding: 9px 30px;
  font-size: 14px;
  text-transform: uppercase;
  min-width: 120px;
  margin-left: 9px;
}

.account-statement button {
  position: absolute !important;
  width: 40px !important;
  height: 33px;
  right: 0;
}
.account-statement button svg {
  fill: #000;
}
.account-statement input[type="date"] {
  z-index: 999999999 !important;
  padding-left: 7px;
  padding-right: 7px;
}
.Live_casino_tabs.hot_games ul li {
  flex: 1;
}

.calendar_icon svg {
  display: none;
}

.email_contact {
  font-size: large;
}

.start-btn {
  border-radius: 30px;
  border: 1px solid #000;
  background: #fcc200;
  color: #000;
  font-weight: 600;
  padding: 10px 25px;
  font-size: 16px;
  text-transform: uppercase;
  margin-right: 10px;
}

@media screen and (max-width: 767.98px) {
  .profile-info.mt-4 {
    padding: 10px;
  }
  *:not(:empty) {
    transform-style: inherit !important;
  }
  .WithdrawPageSection .WithDrawSection.tabs-area {
    width: 100% !important;
  }
  .WithdrawGif {
    display: none;
  }
  .WithDrawSection.tabs-area ul li button {
    font-size: 11px !important;
  }
  .WithDrawBankDetails form {
    padding: 30px 0 0 !important;
  }
  .WithdrawList table {
    width: 100%;
    display: block;
    overflow-x: scroll;
    white-space: nowrap;
  }

  .gatewaySection .tab-content .tab-pane.active .cards1 {
    width: 48% !important;
  }
  .gatewaySection .tab-content {
    padding: 20px !important;
  }
  .gatewaySection {
    max-width: 340px !important;
  }

  #root .main .mainBanner .slick-slide img {
    max-height: inherit !important;
    aspect-ratio: inherit !important;
  }
  .footer .providers_sec::before {
    display: none;
  }
  footer.footer .providers_sec .providers_logo.payment_logo a {
    width: 65px !important;
  }
  .payment-heading {
    margin-top: 20px !important;
  }
  .footer .providers_sec .provider-mob-border {
    border-bottom: 1px solid #7e7e7e;
  }
  .footer .providers_sec .providers_logo .logo_box img {
    max-width: 70px !important;
  }
  .slots-section ul li {
    width: 47.5% !important;
  }
  .footer .footer-menu ul li a span {
    font-size: 11px;
  }
  .contact-social ul li img {
    width: 50px !important;
    height: auto;
    margin: 0 5px !important;
  }
  .sports_page_banner .slick-slider img {
    object-fit: inherit !important;
  }
  .tabs-area .tab-content form .btn {
    margin-bottom: 10px;
    width: 85%;
    margin-right: 0 !important;
  }
  .footer {
    padding: 30px 18px 130px !important;
  }
  .header .headerRight ul {
    display: none !important;
  }
  .copy-right-social-icon {
    flex-wrap: wrap;
    justify-content: center !important;
    align-items: center !important;
  }
  .copy-right-social-icon .socila-icons {
    margin-top: 15px;
  }
  .mainFooter .footer-content {
    margin-bottom: 30px;
  }
  .mainFooter .footer-content .footer-nav {
    width: 100% !important;
  }
  .mainFooter .footer-content .footer-nav h3 {
    font-size: 1.1rem;
  }
  .mainFooter .footer-content .footer-nav ul li a {
    font-size: 15px;
    color: #fff !important;
  }
  .general-foot-content p {
    font-size: 15px;
    color: #fff !important;
  }
  .mainFooter {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .all-games-tabs .games-content ul li {
    width: 40% !important;
    margin: 4.5% !important;
  }
  .all-games-tabs .games-content ul li img {
    border-radius: 15px !important;
  }
  .Evolution_Games .slick-list {
    width: calc(100% - 0px);
    margin-left: 0px;
  }
  .Evolution_Games .slick-prev,
  .Evolution_Games .slick-next {
    display: none !important;
  }
  .shadow.rounded.position-relative {
    padding: 0px 5px;
  }
}
